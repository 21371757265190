/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "./layout.css"
import { Navigation, Splash, HeroSplash, Footer, CallToAction } from "./index"

// const Wrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 80%;
//   margin: 0 auto;
// `

const Layout = ({
  children,
  title,
  text,
  type,
  subtitle,
  ctaType,
  ctaTitle,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {type === "onepage" && (
        <>
          <Splash title={title} type={type} text={text} subtitle={subtitle} />
          <main>{children}</main>
        </>
      )}
      {type === "consultation" && (
        <>
          <Splash title={title} type={type} text={text} subtitle={subtitle} />
          <main>{children}</main>
        </>
      )}
      {type === "success" && (
        <>
          <Splash title={title} type={type} text={text} subtitle={subtitle} />
          <main>{children}</main>
        </>
      )}
      {type === "landingPage" && (
        <>
          <Splash title={title} type={type} text={text} subtitle={subtitle} />

          <main>{children}</main>
        </>
      )}
      {type !== "landingPage" &&
        type !== "onepage" &&
        type !== "consultation" &&
        type !== "success" && (
          <>
            <Navigation siteTitle={data.site.siteMetadata.title} />
            {type === "home" && (
              <Splash
                title={title}
                type={type}
                text={text}
                subtitle={subtitle}
              />
            )}
            {type === "branding" && (
              <Splash
                title={title}
                type={type}
                text={text}
                subtitle={subtitle}
              />
            )}
            {type === "marketing" && (
              <Splash
                title={title}
                type={type}
                text={text}
                subtitle={subtitle}
              />
            )}
            {type === "webdev" && (
              <Splash
                title={title}
                type={type}
                text={text}
                subtitle={subtitle}
              />
            )}
            {type === "app" && (
              <Splash
                title={title}
                type={type}
                text={text}
                subtitle={subtitle}
              />
            )}
            {type !== "home" &&
              type !== "landingPage" &&
              type !== "branding" &&
              type !== "marketing" &&
              type !== "webdev" &&
              type !== "onepage" &&
              type !== "consultation" &&
              type !== "success" &&
              type !== "app" && <HeroSplash title={title} type={type} />}

            <main>{children}</main>
            <CallToAction ctaType={ctaType} ctaTitle={ctaTitle} />
            <Footer />
          </>
        )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
