import React from "react"
import Layout from "../components/layout"
import { Work, Seo } from "../components/index"
import { Container, Wrapper, Column } from "../theme"

const Portfolio = () => {
  return (
    <Layout title="Our Portfolio" type="portfolio">
      <Seo
        title="CheshTech Digital Agency Portfolio"
        description="See the CheshTech Digital Agency work to see examples of how we've helped companies like yours acquire more clients through their websites and increase their revenue."
      />
      <Wrapper white>
        <Container>
          <Column>
            <Work padding="0" />
          </Column>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default Portfolio
